<template>
  <div class="home">
    <!-- <MenuHead msg="Welcome to Your Vue.js App" @func="getMsgFormSon" /> -->
    <router-view></router-view>
    <!-- <WebBottom msg="Welcome to Your Vue.js App" /> -->
<!--    <div-->
<!--        style="position: fixed;z-index: 2000" @click="showSummer()">-->
<!--      <img-->
<!--          class="popActive animated pulse infinite"-->
<!--          style="position: fixed;bottom: 70px;right: 40px;"-->
<!--          src="https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/p2.png"-->
<!--      />-->
<!--      <img-->
<!--          class="popActive"-->
<!--          style="position: fixed;bottom: 45px;right: 38px;"-->
<!--          src="https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/p3.png"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import MenuHead from "@/components/MenuHead.vue";
// import WebBottom from "@/components/WebBottom.vue";
export default {
  name: 'Home',
  // components: {
  //   MenuHead,
  //   WebBottom,
  // },
  mounted() {
  },
  methods: {
    getMsgFormSon(data) {
    },
    showSummer() {
      window.location.href = "https://jinshuju.net/f/aqPE8W";
    },
  },
}
</script>


<style scoped>
.popActive {
  width: 7%;
}

@media screen and (max-width: 800px) {
  .popActive {
    width: 15%;
  }
}

@media screen and (max-width: 500px) {
  .popActive {
    width: 25%;
  }
}
</style>
